require('bc-image-loader');

const _ = require('underscore');
const $ = require('jquery');
global.jQuery = $;

// register Additional Time-related Handlebars helpers
require('../../../../shared/lib/handlebarsHelpersTime')();

// Initialize all global stuff with data from back-end
const { initGlobals } = require('../../common/js/commonSetup');
initGlobals();

const { getSortMethod } = require('../../common/js/sorting');
const { videoFinisher } = require('../../common/js/videofinisher');
const { setupPlayers, teardownPlayers, onPlayerSetup } = require('../../common/js/perform');
const utils = require('../../common/js/utils');

const breakpoints = {
  LARGE: 1200,
  MEDIUM: 992,
  SMALL: 768,
  EXTRA_SMALL: 1,
};

const VIDEO_ASPECT_RATIO = 16 / 9;
const MOBILE_SITE_MARGIN = 10;
const TABLET_SITE_MARGIN = 24;

class Mosaic {
  constructor () {
    this.scrollbarWidth = 0; // detected on document ready using "setScrollbarWidth"
    this.responsiveSize = null;
    this.video = null; // current modal video data
    this.videos = []; // currently loaded videos
    this.templates = [];
    this.modalScrollTop = 0;
    this.nextPage = $('#bc-category-load-more-btn').data('next-page');
    this.animateVideoModalTimeout = 0; // animate video modal content down
  }

  getCurrentCategory () {
    const $categoryInfo = $('#bc-category-info');
    const category = { };

    if (window.category) {// try for window based info, first
      category.name = window.category.name;
      category.slug = window.category.slug;
    } else {
      if ($categoryInfo.length <= 0) {
        return null;
      }
      category.name = $categoryInfo.children('.name').html();
      category.slug = $categoryInfo.children('.slug').html();
    }

    return category;
  }

  determineScrollbarWidth () {
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-detect';
    document.body.appendChild(scrollDiv);

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);

    return scrollbarWidth;
  }

  setScrollbarWidth (width) {
    this.scrollbarWidth = width;
  }

  determineResponsiveSize () {
    const windowWidth = $(window).width() + this.scrollbarWidth;
    if (windowWidth >= breakpoints.LARGE) {
      return breakpoints.LARGE;
    } else if (windowWidth >= breakpoints.MEDIUM) {
      return breakpoints.MEDIUM;
    } else if (windowWidth >= breakpoints.SMALL) {
      return breakpoints.SMALL;
    }

    return breakpoints.EXTRA_SMALL;
  }

  onWindowResize () {
    this.responsiveSize = this.determineResponsiveSize();
    this.onWindowResizeAlways();
  }

  onWindowResizeAlways () {
    this.resizeNavWithMore();
    this.resizeCategoriesList();
    this.resizeVideoModal();
    this.resizeMobileGrid();
  }

  resizeMobileGrid () {
    $('.bc-dc-grid-item').each((index, element) => {
      const $elem = $(element);
      if (!$elem.length || !$($elem[0]).children().length) {
        //this is an empty element used to fill the grid on desktop, but it should be hidden on mobile and shown again when going back to desktop view
        if (this.responsiveSize === breakpoints.EXTRA_SMALL) {
          $elem.css('display', 'none');
        } else {
          $elem.css('display', '');
        }
      }
    });

  }

  resizeCategoriesList () {
    const dropdownItems = [ ];

    const $categoriesList = $('#bc-list-categories');
    let dropdownItemId = null;
    $categoriesList.children('li').each(function () {
      dropdownItemId = $(this).attr('id');
      if (dropdownItemId !== 'bc-list-categories-dropdown' && dropdownItemId !== 'bc-dropdown-search') {
        dropdownItems.push($(this).remove());
      }
    });

    const $categoriesListDropdown = $('#bc-list-categories-dropdown');
    const $categoriesListDropdownMenu = $('#bc-list-categories-dropdown .dropdown-menu');
    $categoriesListDropdownMenu.children('li').each(function () {
      dropdownItems.push($(this).remove());
    });
    let searchbarSize = $('#bc-dropdown-search').outerWidth();
    if (this.responsiveSize === breakpoints.EXTRA_SMALL) { // ignore for mobile
      searchbarSize = 0;
    }
    const availableWidth = $categoriesList.width() - ($('#bc-list-categories-dropdown').outerWidth() + searchbarSize + 20);
    let $dropdownItem = null;
    let usedWidth = 0;
    for (let i = 0; i < dropdownItems.length; i++) {
      $dropdownItem = dropdownItems[i];
      $dropdownItem.insertBefore($categoriesListDropdown);

      usedWidth += $dropdownItem.outerWidth();

      if (usedWidth > availableWidth) {
        $dropdownItem.remove().appendTo($categoriesListDropdownMenu);
      }
    }

    if ($categoriesListDropdownMenu.children().length === 0) {
      $categoriesListDropdown.hide();
    } else {
      $categoriesListDropdown.show();
    }
  }

  resizeNavWithMore () {
    const $navWithMore = $('.bc-nav-with-more');
    const $moreDropdown = $navWithMore.find('.dropdown');
    const $moreDropdownMenu = $navWithMore.find('.dropdown-menu');
    const currentTheme = $navWithMore.attr('data-bc-theme');
    let availableWidth = -1;// will be calculated below

    switch (currentTheme) {
      case 'entertainment_responsive':
      case 'highend_responsive':
        availableWidth = $navWithMore.width() - ($moreDropdown.outerWidth() + 40);
        break;

      default:
        availableWidth = $('.navbar[role=navigation] > .container').width() - (($('.navbar[role=navigation] .navbar-header').outerWidth() + $moreDropdown.outerWidth()) + 40);
        break;
    }

    const dropdownItems = [ ];
    $navWithMore.children('li').each(function () {
      if (!$(this).hasClass('dropdown')) {
        dropdownItems.push($(this).remove());
      }
    });

    $moreDropdownMenu.children('li').each(function () {
      dropdownItems.push($(this).remove());
    });

    if (this.responsiveSize !== breakpoints.EXTRA_SMALL) {// resize for non-mobile
      let usedWidth = 0;
      let $dropdownItem = null;
      for (let i = 0; i < dropdownItems.length; i++) {
        $dropdownItem = dropdownItems[i];
        $dropdownItem.insertBefore($moreDropdown);

        usedWidth += $dropdownItem.outerWidth();

        if (usedWidth >= availableWidth) {
          $dropdownItem.remove().appendTo($moreDropdownMenu);
        }
      }
    } else {// based on count, not width on collapsed
      const maxCount = 4;

      for (let i = 0; i < dropdownItems.length; i++) {
        const $dropdownItem = dropdownItems[i];

        if (i < maxCount) {
          $dropdownItem.insertBefore($moreDropdown);
        } else {
          $dropdownItem.appendTo($moreDropdownMenu);
        }
      }
    }

    if ($moreDropdownMenu.children().length === 0) {
      $moreDropdown.hide();
    } else {
      $moreDropdown.show();
    }

    //Need to wait for the next tick so the navbar items are in the correct positions / layout
    setTimeout(() => this._adjustDropdownPosition(), 0);
  }

  _adjustDropdownPosition () {
    // compare window.width with "more" toggle's position
    // 1. left   -> dropdown should be right
    // 2. right  -> dropdown should be left

    const windowWidth = $(window).width();
    const dropdownButton = $('#bc-list-categories-dropdown');
    const dropdownMenu = $('#bc-list-categories-dropdown .dropdown-menu');

    dropdownMenu.removeClass('left middle right');

    const buttonBoundingBox = dropdownButton.get(0).getBoundingClientRect();

    if (buttonBoundingBox.left >= windowWidth / 2) {
      dropdownMenu.addClass('left');
      dropdownMenu.css('max-width', buttonBoundingBox.right - 10);
    } else {
      dropdownMenu.addClass('right');
      dropdownMenu.css('max-width', windowWidth - buttonBoundingBox.left - 10);
    }
  }

  resizeVideoModal (skipVisibleCheck) {
    const $modal = $('#bc-video-modal');
    if (!skipVisibleCheck && !$modal.is(':visible')) {
      return;
    }

    const $content = $modal.find('.content');
    const $videoContent = $modal.find('.video-content-wrapper');
    const $videoWrapper = $videoContent.find('.video-wrapper');
    const $fmaContent = $modal.find('.fma-content-wrapper');
    const $ctaContainer = $('#bc-overlay-cta-container .bc-cta-container');

    const availableWidth = $content.width();
    let videoWidth = -1; // will be calculated below

    const responsiveSizeSwitch = this.responsiveSize;
    const videoModalFma = $('#bc-video-modal-fma');

    $fmaContent.css('width', '100%');
    $videoContent.css('width', '100%');

    switch (responsiveSizeSwitch) {
      case breakpoints.SMALL:
        videoWidth = $(window).width() - TABLET_SITE_MARGIN * 2;
        break;
      case breakpoints.EXTRA_SMALL:
        videoWidth = $(window).width() - MOBILE_SITE_MARGIN * 2;
        break;
      default:
        $fmaContent.css('width', 'auto');

        if (videoModalFma && videoModalFma.html().length === 0) {
          $fmaContent.css('width', '100%');
          $videoContent.css('width', '100%');
          videoWidth = $videoContent.width();
        } else {
          videoWidth = availableWidth - $fmaContent.outerWidth();
          if ($ctaContainer.data('cta-type') && $ctaContainer.data('cta-type') !== 'none') {
            videoWidth = availableWidth - ($ctaContainer.outerWidth() + 20);
          }
        }

        $videoContent.width(videoWidth);
        break;
    }
    $videoWrapper.width(videoWidth);
    $videoWrapper.height(videoWidth / VIDEO_ASPECT_RATIO);

    $('#bc-video-modal .related-video .video-thumbnail-wrapper').each((index, element) => {
      this.resizeRelatedVideoImage($(element));
    });
  }

  resizeRelatedVideoImage ($thumbnailWrapper) {
    const $relatedVideo = $thumbnailWrapper.closest('.related-video');
    const $thumbnail = $thumbnailWrapper.children('.video-thumbnail');
    const relatedVideoWidth = $relatedVideo.width();

    switch (this.responsiveSize) {
      case breakpoints.EXTRA_SMALL:
        $thumbnail.width('100%');
        $thumbnail.height('auto');
        $thumbnail.css('margin-left', '0px');
        break;

      default:
        $thumbnail.width('auto');
        $thumbnail.height(170);

        if ($thumbnail.width() > relatedVideoWidth) {
          $thumbnail.css('margin-left', -(($thumbnail.width() - relatedVideoWidth) / 2));
        } else {
          $thumbnail.width('100%');
          $thumbnail.css('margin-left', '0px');
        }
        break;
    }
  }

  loadVideo (videoId) {
    // const url = window.bcGallery.getTemplatePath('/sites/mosaic/json/video.json');// @TODO - remove for production, used for sandbox testing
    let url = window.baseUrl + '/api';
    if (window.category) {
      url += '/videos/' + window.category.slug;
    }
    url += '/video/' + videoId;

    $.ajax(url, {
      dataType: 'json',
      success: data => this.onVideoLoaded(data),
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  onVideoLoaded (data, noAnimation) {
    if (!data || !data.result) {
      return;
    }

    this.video = data.result;
    this.viewingPage = $('a[data-video-id="' + this.video.id + '"]').closest('.bc-dc-grid-item-inner').data('page');
    if (data.result.page) { // this is present in response from 'nextVideo' endpoint
      this.viewingPage = data.result.page;
    }
    this.updateURL();
    window.BCLS.analyticsEngine.manualTrackPageView(window.location.pathname + window.location.hash);

    this.updateSharing(this.video, window.translations);
    this.updateDownloadButton(this.video, window.translations);
    this.updateRelatedLinks(this.video, window.translations, window.site);
    this.loadTemplate(utils.getPlayerTemplateUrl(), template => {
      const html = template({
        url: window.location.href,
        video: this.video,
        isSecure: window.location.protocol === 'https:',
        'user-agent': window.navigator.userAgent,
        subPath: window.subPath,
        player: window.bc_gallery.player,
        site: window.site,
      });

      const $videoWrapper = $('#bc-video-modal .video-wrapper');

      if ($videoWrapper.length) {
        teardownPlayers($videoWrapper.get(0));
        $videoWrapper.html(html);
        setupPlayers($videoWrapper.get(0));
      }

      this.updateVideoModalContent(this.video);
      this.resizeVideoModal(true);

      function playerPlay () {
        $videoWrapper.find('.video-js').each(function (index, video) {
          window.videojs(video).ready(function () {
            this.play();
          });
        });
      }

      clearTimeout(this.animateVideoModalTimeout);
      if (!noAnimation) {
        this.setVideoModalOpen(true);

        this.setVideoModalAnimatedDown(false, false);

        this.animateVideoModalTimeout = setTimeout(() => {
          this.setVideoModalAnimatedDown(true, true, playerPlay);
        }, 500);
      } else {
        this.animateVideoModalTimeout = setTimeout(playerPlay, 500);
      }
    });
  }

  updateURL () {
    function escapeForRegExp (value) {
      if (_.isUndefined(value)) {
        return '';
      }
      return value.toString().replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    function trim (value, chars) {
      chars = escapeForRegExp(chars);
      return value.replace(new RegExp('^(' + chars + ')+|(' + chars + ')+$', 'g'), '').toLowerCase();
    }

    function toSlug (value) {
      value = value || '';
      return trim(value.replace(/[^0-9A-Za-z]+/g, '-'), '-').toLowerCase();
    }

    let hash = window.location.hash;
    const detailPattern = /^#id\/[0-9]+$/;
    if (detailPattern.test(hash)) {
      hash = '#id/' + this.video.id;
    }

    // The URL needs to be one that the server understands -> meta tags are populated correctly when shared
    let path = (window.baseUrl || '') + '/category/videos/' + this.getCurrentCategory().slug + '/video/' + this.video.id + '/' + toSlug(this.video.name) + this.buildQueryString() + hash;

    // Make sure we have proper history management APIs.
    if (typeof window.history.replaceState !== 'undefined') {
      window.history.replaceState({}, '', path);

    // Graceful degradation in IE <= 9 (and possibly other legacy browsers) -- force a full page reload.
    } else {
      path = window.location.protocol + '//' + window.location.host + path;
      window.location = path;
    }
  }

  updateSharing (videoData, translations) {
    if (!videoData) {
      return;
    }
    const url = window.baseUrl + '/api/social';

    $.ajax(url, {
      dataType: 'json',
      success: data => {
        if (!data || !data.result || !data.result.social) {
          return;
        }
        this.loadTemplate(window.bcGallery.getTemplatePath('/components/share_responsive.hbs'), function (template) {
          const html = template({
            url: window.location.href,
            shareUrl: window.location.href,
            video: videoData,
            site: data.result,
            social: data.result.social,
            translations: translations,
            subPath: window.subPath,
          });
          $('#bc-video-modal .social-wrapper').html(html);
        });
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  updateDownloadButton (videoData, translations) {
    this.loadTemplate(window.bcGallery.getTemplatePath('/components/downloadLink.hbs'), function (template) {
      const html = template({
        video: videoData,
        translations: translations,
        subPath: window.subPath,
      });
      $('#bc-video-modal #video-download').replaceWith(html);
    });
  }

  updateRelatedLinks (videoData, translations, site) {
    this.loadTemplate(window.bcGallery.getTemplatePath('/components/relatedLinks.hbs'), function (template) {
      const html = template({
        site: site,
        video: videoData,
        translations: translations,
      });
      $('#bc-video-modal .related-link').html(html);
    });
  }

  loadTemplate (url, callback) {
    if (this.templates[url]) {
      return callback(this.templates[url]);
    }
    $.ajax({
      url: url,
      success: contents => {
        this.templates[url] = window.bcGallery.Handlebars.compile(contents);
        callback(this.templates[url]);
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  setVideoModalOpen (isOpen) {
    const $body = $('body');
    const $modal = $('#bc-video-modal');
    const $modalCurtain = $('#bc-video-modal-curtain');
    const windowScrollTop = $(window).scrollTop();

    if (isOpen) {
      this.modalScrollTop = windowScrollTop;

      $body.addClass('no-scroll').css('margin-top', -windowScrollTop);
      $modal.removeClass('no-scroll-ie');
      $('#bc-video-modal').css('width', '100%');
      $modal.stop().fadeIn();
      $modalCurtain.stop().fadeIn();
    } else {
      window.location.hash = '';// remove hash
      this._cleanUpPlayerOnClose(() => {
        $modal.stop().fadeOut(function () {
          // fadeOut set display to none, this interferes with how the smart player works in HTML mode, resetting it.
          $('#bc-video-modal').css({
            width: '0',
            display: '',
          });
        });

        $modalCurtain.stop().fadeOut(() => {
          $body.css('margin-top', 0).removeClass('no-scroll');
          $modal.addClass('no-scroll-ie');
          $(window).scrollTop(this.modalScrollTop);
          this.setVideoModalAnimatedDown(false, false);
        });
      });
    }
  }

  setVideoModalAnimatedDown (isAnimatedDown, useAnimation, callback) {
    if (!callback) {
      callback = function () {};
    }
    const $modalInner = $('#bc-video-modal .content-inner');
    const modalInnerMarginTop = isAnimatedDown ? 0 : -1000;

    function updateBodyHeight () {
      const body = $('body');
      if (isAnimatedDown) {
        // modal open
        const modalHeight = $('.bc-video-modal .container').height();
        const bodyHeight = body.height();
        if (modalHeight > bodyHeight) {
          body.height(modalHeight);
        }
      } else {
        // modal close
        // reset height to original body height
        body.height('');
      }
    }

    if (!useAnimation) {
      $modalInner.stop().css('margin-top', modalInnerMarginTop);
      updateBodyHeight();
      callback();
      return;
    }

    $modalInner.stop().animate({
      'margin-top': modalInnerMarginTop,
    }, {
      duration: 800,
      done: function () {
        updateBodyHeight();
        callback();
      },
    });
  }

  loadMoreVideos () {
    let url = window.baseUrl + '/api/videos';
    const searchQuery = $('#bc-category-load-more-btn').data('search-query');
    const data = { };
    if (searchQuery) {
      data.q = searchQuery;
    } else {
      const category = this.getCurrentCategory();
      if (category && category.slug) {
        url += '/' + category.slug;
      }
    }

    data.page = this.nextPage;
    if (getSortMethod(true)) {
      data['sort_by'] = getSortMethod(true);
    }

    $.ajax(url, {
      dataType: 'json',
      data: data,
      success: data => this.onMoreVideosLoaded(data),
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  onMoreVideosLoaded (data) {
    if (!data || !data.result) {
      return;
    }

    const videos = data.result.items;
    if (!data.result.nextPage) {
      $('#bc-category-load-more-btn').hide();
    }

    this.nextPage = data.result.nextPage;

    const templateData = {
      baseUrl: window.baseUrl,
      category: this.getCurrentCategory(),
      videos: videos,
      page: data.result.page,
      imageTranscoder: utils.getImageTranscoder(),
      subPath: window.subPath,
    };

    this.loadTemplate(window.bcGallery.getTemplatePath('/sites/mosaic/partials/videoGridRow.hbs'), template => {
      const html = template(templateData);
      const hasCta = $('.first-grid').hasClass('with-cta');
      const $gridContainer = $('#bc-dc-grid-container');

      $gridContainer.append(html);
      window.bcGallery.imageLoader.loadImages();
      if (hasCta) {
        $gridContainer.find('> .row-fluid').addClass('with-cta');
      }
      this.resizeMobileGrid();
    });
  }

  updateVideoModalContent (video) {
    // jQuery.html(null) will not do anything. Empty string needs to passed to remove existing text
    $('#bc-video-modal .video-name').html(video.name || '');
    $('#bc-video-modal .video-description').html(utils.getVideoDescription(video));
    this.loadRelatedVideos(video.id);
    this.loadVideoCta(video.id);
  }

  loadRelatedVideos (videoId) {
    $('#bc-video-modal .related-content').hide();

    const category = this.getCurrentCategory();
    if (!category || !category.slug || !videoId) {
      return;
    }

    // const url = window.bcGallery.getTemplatePath('/sites/mosaic/json/videos-related.json');// @TODO - remove for production - used for sandbox testing
    const url = window.baseUrl + '/api/videos/' + category.slug + '/video/' + videoId + '/related';
    $.ajax(url, {
      dataType: 'json',
      success: data => this.onRelatedVideosLoaded(data),
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  onRelatedVideosLoaded (data) {
    if (!data || !data.result) {
      return;
    }

    const relatedVideos = data.result;
    let $relatedVideo = null;
    let video = null;
    for (let i = 0; i < 3; i++) {// assumes 3 spots always (hides spots without a video)
      $relatedVideo = $('.related-video-' + (i + 1));
      $relatedVideo.hide();

      video = i < relatedVideos.length ? relatedVideos[i] : null;
      if (!video) {
        $relatedVideo.hide();
        continue;
      }

      $relatedVideo.find('.related-video-link-wrapper').data('video-id', video.id).attr('href', '#id/' + video.id);
      $relatedVideo.find('img.video-thumbnail').attr('src', video.galleryThumbnailURL);
      $relatedVideo.find('.video-thumbnail-name').html(video.name || '');
      $relatedVideo.find('.video-thumbnail-timestamp').html(this.videoLengthToTimestamp(video.length) || '');
      $relatedVideo.show();

      $('#bc-video-modal .related-content').show();
    }
  }

  loadVideoCta (videoId) {
    $.ajax(window.baseUrl + '/api/ctas/' + window.site.activePage + '/' + videoId + '/true?isStudio=' + $('body').hasClass('bc-studio'), {
      type: 'get',
      dataType: 'json',
      success: data => this.onVideoCtaLoaded(data, true, true),
      error: function () {
        console.warn('BCLSMOSAIC - error loading CTA content');
      },
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  onVideoCtaLoaded (data) {
    const isStudio = $('body').hasClass('bc-studio');
    const ctas = data && data.result ? data.result : null;
    const $cta = $('#bc-overlay-cta-container');
    const $fma = $('.fma-content-wrapper');

    const $modalInner = $('#bc-video-modal .content-inner');
    $modalInner.removeClass('with-cta');
    if (ctas && ctas['overlay'] && (ctas['overlay'].type !== 'none' || isStudio)) {
      $fma.hide();
      $cta.show();
      $modalInner.addClass('with-cta');
    } else {
      $fma.show();
      $cta.hide();
      return;
    }

    this.loadTemplate(window.bcGallery.getTemplatePath('/components/cta.hbs'), template => {
      // prevent HTML replacement in WYSIWYG so it remains clickable
      if ($('body').hasClass('bc-studio')) {
        return;
      }

      const html = template({
        id: window.site.isMobile ? 'overlay-mobile' : 'overlay',
        ctas: data.result,
        site: window.site,
        subPath: window.subPath,
      });

      $('#bc-overlay-cta-container').html(html);

      // resize for new HTML
      this.resizeVideoModal();
    });
  }

  videoLengthToTimestamp (length) {
    if (length < 0) {
      const translations = window.translations || {};
      //we have a live event
      return translations['LIVE'] || 'LIVE';
    }

    const hours = parseInt(length / 1000 / 60 / 60, 10);
    const minutes = parseInt(length / 1000 / 60 % 60, 10);
    let strMinutes = minutes.toFixed(0);
    if (minutes < 10) {
      strMinutes = '0' + strMinutes;
    }
    const seconds = parseInt(length / 1000 % 60, 10);
    let strSeconds = seconds.toFixed(0);
    if (seconds < 10) {
      strSeconds = '0' + strSeconds;
    }

    if (hours === 0 && minutes === 0) {
      return '0:' + strSeconds;
    } else if (hours === 0) {
      return minutes + ':' + strSeconds;
    }

    return hours + ':' + strMinutes + ':' + strSeconds;
  }

  nextVideo () {
    const videoId = this.video.id;
    const category = this.getCurrentCategory();
    if (!category || !category.slug || !videoId) {
      return;
    }

    const url = window.baseUrl + '/api/videos/' + category.slug + '/video/' + videoId + '/next' + this.buildQueryString();
    $.ajax(url, {
      dataType: 'json',
      success: data => this.onVideoLoaded(data, true, true),
      complete: function (xhr) {
        if (xhr.status === 401) {
          window.location.replace(window.baseUrl + '/login?redirect=' + encodeURIComponent(window.location.href));
        }
      },
    });
  }

  /**
   * In case of perform player, we want to leave the HTML behind since removing
   * HTML causes unexpected behavior[#GT-753]. However, if the HTML is present,
   * the video continues to play. Solution is to pause.
   * In case of smart player, we remove the HTML
   */
  _cleanUpPlayerOnClose (cb) {
    clearTimeout(this.animateVideoModalTimeout);
    const $videoWrapper = $('#bc-video-modal .video-wrapper');
    $videoWrapper.find('.video-js').each(function (index, video) {
      window.videojs(video).pause();
    });
    cb();
  }

  buildQueryString () {
    const qsTokens = [];
    const sortMethod = getSortMethod(true);
    if (sortMethod) {
      qsTokens.push('sort_by=' + sortMethod);
    }

    if (window.bcGallery.mute) {
      qsTokens.push('mute=true');
    }
    if (window.query && window.query.q) {
      qsTokens.push('q=' + window.query.q);
    }
    if (this.viewingPage) {
      qsTokens.push('page=' + this.viewingPage);
    }

    let queryString = '';
    if (qsTokens.length > 0) {
      queryString = '?' + qsTokens.join('&');
    }

    return queryString;
  }
}

const mosaic = new Mosaic();

$(document).ready(function () {
  window.bcGallery.imageLoader.ignoreHeight = true;
  window.bcGallery.imageLoader.loadImages();
  $('#bc-dc-grid').on('click', 'a.video-link-wrapper', function (e) {
    mosaic.loadVideo($(e.currentTarget).data('video-id'));
  });

  $('#bc-video-modal').on('click', 'a.related-video-link-wrapper', function (e) {
    mosaic.loadVideo($(e.currentTarget).data('video-id'));
  });

  $('#bc-video-modal').on('click', '.close-button a', function (e) {
    e.preventDefault();
    mosaic.setVideoModalOpen(false);
  });

  $('#bc-category-load-more-btn').on('click', 'a', function (e) {
    e.preventDefault();
    mosaic.loadMoreVideos();
  });

  mosaic.setScrollbarWidth(mosaic.determineScrollbarWidth());
  mosaic.onWindowResize();

  // load video if we have a hash on page load
  if (window.location.hash.length > 0) {
    mosaic.loadVideo(window.currentVideo.id);
  } else {
    let url = window.location.pathname;
    if (window.baseUrl) {
      url = url.substr(window.baseUrl.length);
    }
    const parts = url.split('/');
    if (parts.length >= 6) {
      mosaic.loadVideo(parts[5]);
    }
  }

  videoFinisher.onNextVideo(function () {
    mosaic.nextVideo();
  });
});

$(window).resize(function () {
  mosaic.onWindowResize();
});

window.BCLS = window.BCLS || {};
window.BCLS.config = {
  reloadOnChange: false,
};

//Disable autoplay on all players
onPlayerSetup(function (player) {
  player.autoplay(false);
});

window.BCLS.afterReadyHandler = function () {
  mosaic.resizeVideoModal();
};
